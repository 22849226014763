<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">考勤管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
	<!--	考勤表-->
		<div id="forms_div2">
			<div style="display: flex;width: 100%">
				<!--	局指选择工资发放标段-->
				<div v-if="GET_BDST === '1'" style="margin-left: 2%">
					<el-select v-model="salary_post" class="m-2" placeholder="请选择工资发放标段" size="large" @change="getUserDays">
						<el-option
							v-for="item in salary_posts"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
			<!--	选择月份-->
				<div>
					<el-date-picker
						v-model="month"
						type="month"
						placeholder="请选择考勤月份"
						format="YYYY-MM"
						value-format="YYYY-MM"
						@change="getUserDays"
					/>
				</div>
				<div>
					<el-select v-model="employ_type" class="m-2" placeholder="请选择用工形式" size="large" clearable  @change="getUserDays">
						<el-option
							v-for="item in employ_types"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
				<div v-if="this.employ_type === 'lsyg_xsg'">
					<el-input v-model="xsg_time" placeholder="请输入小时工每日工作时长" @change="getUserDays"/>
				</div>
				<div v-if="button_flag">
					<el-button @click="previewKaoqin" type="primary">预览考勤</el-button>
					<el-button @click="saveKaoqin" type="danger">保存考勤</el-button>
				</div>
				<div v-if="button_flag" style="display: flex;margin-left: 2%">
					<el-upload
						ref="upload"
						class="upload-demo"
						action="#"
						:auto-upload="false"
						:data="dataup"
						:multiple="false"
						:on-change="imgchange"
						:on-remove="handleRemove"
						:limit="1"
					>
						<template #trigger>
							<el-button type="primary">选择考勤表</el-button>
						</template>
						<div></div>
					</el-upload>
					<div>
						<el-button @click="upKaoqin" >上传</el-button>
					</div>
				</div>
				<div v-if="!button_flag" style="margin-left: 2%">
					<el-button @click="sendKaoqin" type="success">发送考勤</el-button>
					<el-button @click="removeKaoqin" type="danger">删除考勤</el-button>
				</div>
			</div>

		<!--	人员考勤表-->
			<div style="margin-top: 1%">
				<vxe-table
					border
					height="700"
					:align="allAlign"
					:column-config="{isCurrent: true, isHover: true, resizable: true}"
					:row-config="{isCurrent: true, isHover: true}"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:checkbox-config="{highlight: true, range: true}"
					:data="kaoqin_list">
					<vxe-column type="seq" width="50" fixed="left"></vxe-column>
					<vxe-column field="realName" title="姓名" width="125" fixed="left"></vxe-column>
					<vxe-colgroup :title="month">
						<vxe-column v-if="button_flag" v-for="(item, index) in month_days" :field="'kaoqin' + (index + 1)" :title="index + 1 + ''" width="38" :edit-render="{}">
							<template #edit="{ row }">
<!--								<vxe-input v-model="row.['kaoqin' + (index + 1)]" type="text" placeholder="请输入昵称"></vxe-input>-->
							</template>
						</vxe-column>
						<vxe-column v-if="!button_flag" v-for="(item, index) in month_days" :field="'kaoqin' + (index + 1)" :title="index + 1 + ''" width="38">
							<template #edit="{ row }">
<!--								<vxe-input v-model="row.['kaoqin' + (index + 1)]" type="text" placeholder="请输入昵称"></vxe-input>-->
							</template>
						</vxe-column>
					</vxe-colgroup>

					<vxe-column field="cq" title="出勤√" width="38"></vxe-column>
					<vxe-column field="cc" title="出差E" width="38"></vxe-column>
					<vxe-column field="xx" title="学习L" width="38"></vxe-column>
					<vxe-column field="nxj" title="年休假V" width="38"></vxe-column>
					<vxe-column field="hj" title="婚假W" width="38"></vxe-column>
					<vxe-column field="sj" title="丧假D" width="38"></vxe-column>
					<vxe-column field="tq" title="探亲F" width="38"></vxe-column>
					<vxe-column field="cj" title="产假M" width="38"></vxe-column>
					<vxe-column field="bj" title="病假S" width="38"></vxe-column>
					<vxe-column field="dg" title="待岗X" width="38"></vxe-column>
					<vxe-column field="shij" title="事假P" width="38"></vxe-column>
					<vxe-column field="kg" title="旷工A" width="38"></vxe-column>
					<vxe-column field="flag" title="是否确认" width="110">
						<template #default="{ row }">
							<el-tag v-if="row.flag === 1 && !button_flag" type="success">已确认</el-tag>
							<el-button v-if="row.flag !== 1 && !button_flag" type="danger" size="mini" @click="updataKaoqin(row)">修改考勤</el-button>
						</template>
					</vxe-column>
				</vxe-table>
			</div>
		</div>
	<!--	修改考勤弹窗-->
		<div>
			<el-dialog
				v-model="dialogVisible"
				:title="updata_kaoqin_username"
				width="500"
			>
				<div>
					<vxe-table
						border
						height="700"
						:align="allAlign"
						:column-config="{isCurrent: true, isHover: true, resizable: true}"
						:row-config="{isCurrent: true, isHover: true}"
						:edit-config="{trigger: 'click', mode: 'cell'}"
						:checkbox-config="{highlight: true, range: true}"
						:data="kaoqin_list_updata">
						<vxe-column type="seq" width="50" fixed="left"></vxe-column>
						<vxe-column field="realName" title="姓名" width="125" fixed="left"></vxe-column>
						<vxe-colgroup :title="month">
							<vxe-column v-for="(item, index) in month_days" :field="'kaoqin' + (index + 1)" :title="index + 1 + ''" width="38" :edit-render="{}">
								<template #edit="{ row }">
<!--									<vxe-input v-model="row.['kaoqin' + (index + 1)]" type="text" placeholder="请输入昵称"></vxe-input>-->
								</template>
							</vxe-column>
						</vxe-colgroup>

						<vxe-column field="cq" title="出勤√" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.cq" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="cc" title="出差E" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.cc" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="xx" title="学习L" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.xx" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="nxj" title="年休假V" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.nxj" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="hj" title="婚假W" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.hj" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="sj" title="丧假D" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.sj" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="tq" title="探亲F" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.tq" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="cj" title="产假M" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.cj" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="bj" title="病假S" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.bj" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="dg" title="待岗X" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.dg" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="shij" title="事假P" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.shij" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="kg" title="旷工A" width="38" :edit-render="{}">
							<template #edit="{ row }">
								<vxe-input v-model="row.kg" type="text" placeholder="请输入昵称"></vxe-input>
							</template>
						</vxe-column>
						<vxe-column field="flag" title="是否确认" width="110">

						</vxe-column>
					</vxe-table>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="updataKaoqinCancel">取消</el-button>
						<el-button type="primary" @click="updataKaoqinConfirm"> 确认修改 </el-button>
					</div>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import {setNowMonth2, setNowTimes} from "@/utils/time";

export default {
	name: "",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			fileList: [],
			dataup: {
				file: '',
				userid: ''
			},
			salary_post: '2标',
			salary_posts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}
			],
			kaoqin_list: [],
			month_days: '',
			allAlign: 'center',
			month: setNowMonth2(),
			kaoqin: '',
			kaoqins: [
				{
					value: '√',
					label: '√',
				},
				{
					value: 'E',
					label: 'E',
				},
				{
					value: 'L',
					label: 'L',
				},
				{
					value: 'V',
					label: 'V',
				},
				{
					value: 'W',
					label: 'W',
				},
				{
					value: 'D',
					label: 'D',
				},
				{
					value: 'F',
					label: 'F',
				},
				{
					value: 'M',
					label: 'M',
				},
				{
					value: 'S',
					label: 'S',
				},
				{
					value: 'X',
					label: 'X',
				},
				{
					value: 'P',
					label: 'P',
				},
				{
					value: 'A',
					label: 'A',
				}
			],
			kaoqin_show: [],
			preview_flag: false,
			button_flag: true,
			employ_type: '',
			employ_types: [
				{
					value: 'formal',
					label: '正式工',
				},
				{
					value: 'temporary',
					label: '合同工',
				},
				{
					value: 'labor_@lwwb@lsyg@lsyg_7000@lsyg_3500_xs@lsyg_4250_xs@lsyg_3000_xs@lsyg_2500_xs@lsyg_5000@lsyg_3500@lsgy_xsg',
					label: '劳务派遣',
				},
				{
					value: 'ztd_11900@ztd_8400@ztd_9400@ztd_7900@ztd_11000',
					label: '钻探队',
				}
			],
			xsg_time: '',
			dialogVisible: false,
			updata_kaoqin_username: '',
			kaoqin_list_updata: []
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}else {
			this.getUserDays()
		}
	},
	methods: {
		getUserDays(){
			if (this.GET_BDST !== '1'){
				this.salary_post = this.GET_BDST
			}
			this.preview_flag = false
			this.axios.get('/ucUser/generateUserKaoqin', (response) => {
				this.kaoqin_list = response.obj
				this.month_days = response.obj2
				this.button_flag = response.obj3
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				month: this.month,
				employ_type: this.employ_type,
				xsg_time: this.xsg_time
			})
		},
		previewKaoqin(){
			this.preview_flag = true
			for (let i = 0; i < this.kaoqin_list.length; i++){
				let flag1 = '';
				for (let y = 1; y <= this.month_days; y++){
					// flag1 = flag1 + this.kaoqin_list[i].['kaoqin' + y]
				}
				this.kaoqin_list[i].flag1 = flag1
			}
			this.axios.post('/ucUser/previewKaoqin', (response) => {
				this.kaoqin_list = response.obj
			},{
				wageKaoqinVo: JSON.stringify(this.kaoqin_list)
			})
		 },
		saveKaoqin(){
			if (!this.preview_flag){
				ElMessage.error('请先预览生成完整考勤!')
			}else {
				this.axios.post('/wageKaoqin/saveKaoqin', (response) => {
					if (response.obj){
						ElMessage.success('考勤表保存成功!')
						this.getUserDays()
					}
				},{
					wageKaoqin: JSON.stringify(this.kaoqin_list),
					month: this.month,
					salary_post: this.salary_post,
					bdst: this.GET_BDST,
					up_user: this.GET_USER,
					up_time: setNowTimes(new Date()),
					employment_type: this.employ_type
				})
			}

		},
		removeKaoqin(){
			this.axios.get('/wageKaoqin/removeKaoqin', (response) => {
				if (response.obj){
					ElMessage.success('删除成功!')
					this.getUserDays()
				}else {
					ElMessage.error('删除失败，请联系科信部!')
				}
			},{
				salary_post: this.salary_post,
				month: this.month,
				bdst: this.GET_BDST
			})
		},
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		upKaoqin(){
			this.dataup.file = this.fileList[0].raw;
			this.axios.post('/wageKaoqin/upKaoqinFile', (response) => {
				if (response.obj){
					ElMessage.success('考勤上传成功!')
					this.getUserDays()
				}else{
					ElMessage.error('考勤上传失败，请联系科信部!')
				}
			},{
				file: this.dataup.file,
				month: this.month,
				salary_post: this.salary_post,
				bdst: this.GET_BDST,
				up_user: this.GET_USER,
				up_time: setNowTimes(new Date()),
				employ_type: this.employ_type
			})
		},
		sendKaoqin(){
			this.axios.get('/wageKaoqin/sendKaoQin', (response) =>{
				if (response.code === 200){
					ElMessage.success('发送成功!')
				}else {
					ElMessage.error('发送失败!')
				}

			},{
				month: this.month,
				kq_post: this.salary_post
			})
		},
		updataKaoqin(row){
			this.updata_kaoqin_username = '修改' + row.realName + '考勤'
			this.kaoqin_list_updata.push(row)
			this.dialogVisible = true
		},
		updataKaoqinCancel(){
			this.kaoqin_list_updata = []
			this.dialogVisible = false
		},
		updataKaoqinConfirm(){
			this.axios.post('/wageKaoqin/updateKaoqin', (response) => {
				if (response.obj){
					ElMessage.success('修改成功!')
					this.kaoqin_list_updata = []
					this.dialogVisible = false
					this.getUserDays()
				}
			},{
				wage_kaoqin: JSON.stringify(this.kaoqin_list_updata)
			})
		}

	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 750px;
}
</style>